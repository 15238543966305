// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-candy-bar-js": () => import("./../../../src/pages/candy-bar.js" /* webpackChunkName: "component---src-pages-candy-bar-js" */),
  "component---src-pages-ceremonie-js": () => import("./../../../src/pages/ceremonie.js" /* webpackChunkName: "component---src-pages-ceremonie-js" */),
  "component---src-pages-decoration-de-salle-js": () => import("./../../../src/pages/decoration-de-salle.js" /* webpackChunkName: "component---src-pages-decoration-de-salle-js" */),
  "component---src-pages-decoration-de-table-js": () => import("./../../../src/pages/decoration-de-table.js" /* webpackChunkName: "component---src-pages-decoration-de-table-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mes-jolis-contenants-js": () => import("./../../../src/pages/mes-jolis-contenants.js" /* webpackChunkName: "component---src-pages-mes-jolis-contenants-js" */),
  "component---src-pages-mobiliers-et-housses-js": () => import("./../../../src/pages/mobiliers-et-housses.js" /* webpackChunkName: "component---src-pages-mobiliers-et-housses-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-articles-item-jsx": () => import("./../../../src/templates/articles-item.jsx" /* webpackChunkName: "component---src-templates-articles-item-jsx" */)
}

